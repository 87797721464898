import store from "../store"
export const enlacesMenu = (pais) => {
  return [
    {
      label: "Panel de control",
      icon: "pi pi-fw pi-home",
      to: "/",
    },
    {
      label: "Clientes",
      icon: "pi pi-fw pi-user",
      to: "/clientes",
    },
    {
      label: "Contactos",
      icon: "pi pi-fw pi-id-card",
      to: "/contactos",
    },
    {
      label: "Maestros",
      icon: "pi pi-briefcase",
      items: [
        {
          label: "Países",
          icon: "pi pi-list",
          to: "/paises",
        },
        {
          label: "Atributos",
          icon: "pi pi-list",
          to: "/atributos",
        },
        {
          label: "Cotizador",
          to: "/cotizador",
        },
        {
          label: "Productos",
          icon: "pi pi-tag",
          to: "/productos",
        },
        {
          label: "Tipos de contrato",
          icon: "pi pi-file",
          to: "/tipos-contrato",
        },
      ],
    },
    {
      label: "Herramientas ERP",
      icon: "pi pi-fw pi-cog",
      visible: store.getters[`auth/canManageERP`],
      items: [
        {
          label: "Administrador de archivos",
          to: `${store.getters.rutaBase}/administrador-archivos`,
        },
        {
          label: "Administrador de base de datos",
          to: `/erp/administrador-base-datos`,
        },
        {
          label: "Historial de script",
          to: `/erp/historial-scripts`,
        }
      ],
    },
    {
      label: "Garantto",
      icon: "pi pi-google",
      items: [
        {
          label: "Marcas",
          to: `/garantto/${pais}/marcas`,
        },
        {
          label: "Productos",
          to: `/garantto/${pais}/productos`,
        },
        {
          label: "Compañías",
          to: `/garantto/${pais}/companias`,
        },
      ],
    },
    {
      label: "Soporte",
      icon: "pi pi-question-circle",
      items: [
        {
          label: "CSA",
          to: "/soporte/csa",
        },
        {
          label: "CMMS",
          to: "/soporte/cmms",
        },
      ],
    },
  ];
};
