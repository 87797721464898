const state = {
  user: {},
  asset_url: location.origin
};

const getters = {
  userCanEdit: (state) => state.user?.Editar == 1,
  userCanEditGarantto: (state) => state.user?.EditarGarantto == 1,
  userCanEditCertificados: (state) => state.user?.EditarCertificados == 1,
  userCanReadCertificados: (state) => state.user?.LeerCertificados == 1,
  isLogged: (state) => Object.keys(state.user).length > 0,
  canManageERP: (state) => state.user?.AdministrarErp == true,
};

const actions = {};

const mutations = {
  login: (state, user) => {
    state.user = user;
  },
  logout: (state) => {
    state.user = {};
  },
  setAssetUrl: (state, url) => state.asset_url = url
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
