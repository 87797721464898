import axios from "./config";

/**
 * Obtener las bases de datos de ERP
 * @returns {Promise<{status: number, data: { [key: string]: Array<string> }}>} status: estado de la petición, data: bases de datos de ERP
 */
export const getErpDatabases = async () => {
  const { status, data } = await axios.get('/erp/database-administrator').catch(e => e.response);
  return {
    status,
    data,
  }
}

/**
 * Ejecutar un script en las bases de datos de ERP seleccionadas
 * @param {File} file Archivo SQL a ejecutar
 * @param {Array<{server:string,database:string}>} databases Bases de datos de ERP seleccionadas
 * @returns {Promise<{status: number, message:string, data: Array<{statusCode:number, database:string, date:string, message:string}>}>}
 */
export const runErpDatabaseScript = async (file, databases) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('databases', JSON.stringify(databases));

  const { status, data } = await axios.post('/erp/database-administrator/execute-script', formData).catch(e => e.response);
  return { status, ...data };
}

/**
 * Obtener el historial de ejecución de scripts en las bases de datos de ERP
 * @returns {Promise<{status: number, scripts: Array<{Id:number, NombreArchico:string, FechaProgramacion:string, FechaEjecucion:string, results:Array<{Id:number, BaseDatos:string, CodigoEstado:string, EsError:boolean, Mensaje:string, Fecha:string}>}}}
 */
export const getErpDatabaseScriptHistory = async () => {
  const { status, data } = await axios.get('/erp/database-administrator/history').catch(e => e.response);
  return { status, scripts: data };
}

/**
 * Eliminar un script de la base de datos de ERP
 * @param {number} id Id del script
 * @param {string} type tipo de registro
 * @returns {Promise<{status: number, message:string}>}
 */
export const deleteErpDatabaseScript = async (id, type) => {
  const { status, data } = await axios.delete(`/erp/database-administrator/history/${id}/${type}`).catch(e => e.response);
  return { status, ...data };
}
