<template>
  <div class="border-round-md shadow-lg bg-white">
    <table class="script-history-by-script-main-table">
      <thead class="bg-cyan-500 text-white">
        <tr>
          <th>Script</th>
          <th>Total</th>
          <th>Avance</th>
          <th>Acción</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(item, index) in scripts" :key="item.Script">
          <tr>
            <td class="text-center">
              <span class="text-700">{{ item.NombreArchico }}</span>
            </td>
            <td class="text-center">
              <Tag :value="item.total"></Tag>
            </td>
            <td>
              <ProgressBar :value="calculePercentage(item)"></ProgressBar>
            </td>
            <td>
              <Button type="button" :icon="`pi ${index == selectedIndex ? 'pi-minus' : 'pi-plus'}`"
                class="p-button-rounded p-button-primary p-button-text" @click="toggleDetail(index)" />
              <Button type="button" icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-text"
                @click="confirmDelete(item, 'script', index.toString())" />
            </td>
          </tr>
          <tr class="subtable" :class="{ 'd-none': index != selectedIndex }">
            <td colspan="4">
              <table class="table-striped">
                <thead class="text-center">
                  <tr>
                    <th class="text-left">DDBB</th>
                    <th class="text-left">Fecha</th>
                    <th class="text-left">Código</th>
                    <th class="text-left">Mensaje</th>
                    <th class="text-right"></th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(child, index2) in item.results" :key="`script-childre-${child.Id}`">
                    <tr>
                      <td>{{ child.BaseDatos }}</td>
                      <td>{{ child.FechaEjecucion }}</td>
                      <td>{{ child?.Codigo ?? "200" }}</td>
                      <td>{{ child.Mensaje }}</td>
                      <td>
                        <Button type="button" icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-text"
                          @click="confirmDelete(child, 'result', `${index},${index2}`)" />
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>
<script setup>
import { defineProps, ref, defineEmits } from 'vue';
import ProgressBar from 'primevue/progressbar';
import Button from 'primevue/button';
import Tag from 'primevue/tag';
import Swal from 'sweetalert2';
import { deleteErpDatabaseScript } from '@/services/erpDatabaseAdministrator.service';
import { useStore } from 'vuex';

defineProps({
  scripts: Array,
})
const store = useStore();
const emit = defineEmits(["delete"]);

const selectedIndex = ref(null);

const calculePercentage = ({ total, totalErrors }) => {
  if (total === 0) return 0;
  if (totalErrors === 0) return 100;
  const calcule = (1 - (totalErrors / total)) * 100;
  return Math.round(calcule);
}

const toggleDetail = (index) => {
  if (selectedIndex.value === index) {
    selectedIndex.value = null;
  } else {
    selectedIndex.value = index;
  }
}

/**
 *
 * @param {object} model
 * @param {'script'|'result'} type
 * @param {string} index
 */
const confirmDelete = (model, type, index) => {
  Swal.fire({
    title: '¿Estás seguro?',
    text: "¡No podrás revertir esto!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Sí, bórralo!'
  }).then((result) => {
    if (result.isConfirmed) {
      handleDeleteItem(model.Id, type, index);
    }
  })
}

/**
 *
 * @param {number} id
 * @param {'script'|'result'} type
 * @param {string} index
 */
const handleDeleteItem = async (id, type, index) => {
  store.commit("setIsLoading", true)
  const { status, message } = await deleteErpDatabaseScript(id, type);
  store.commit("setIsLoading", false)

  if (status !== 200) {
    Swal.fire('Error', message, 'error');
    return;
  }

  // Confimar eliminación
  index = index.split(',');
  if (index.length === 1)
    selectedIndex.value = null;
  Swal.fire('Eliminado', message, 'success');
  emit("delete", { index });
}

</script>

<style lang="scss" scoped>
.d-none {
  display: none;
}

.table-striped {
  tbody {
    tr:nth-child(odd) {
      background-color: #f2f2f2;
    }
  }
}

table {
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    padding: .3rem;
  }
}
</style>
