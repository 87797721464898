export const callbackLogin = (to, from, next) => {
  const { responseLogin } = to.params;
  const { status } = JSON.parse(responseLogin);
  let errorMessage = null;

  if (status === 200) {
    location.href = "/";
    return;
  }
  if (status === 403) {
    errorMessage =
      "El correo electrónico indicado no esta autorizado para el ingreso.";
  }
  if (status === 401) {
    errorMessage = "Su cuenta no se encuentra habilitada.";
  }
  if (status === 500) {
    errorMessage = "Ocurrió un error interno, por favor intente mas tarde.";
  }
  next({ name: "login", params: { errorMessage } });
};
