import { createRouter, createWebHistory } from "vue-router";

// Componentes
import DefaultLayout from "../layout/_default.vue";
import HomeView from "../views/HomeView.vue";
import ClientesView from "../views/cliente/ClientesView.vue";
import ClienteCrear from "../views/cliente/ClienteCrear.vue";
import ClienteEditar from "../views/cliente/ClienteEditar.vue";
import ContactosView from "../views/ContactosView.vue";
import ProductosView from "../views/maestros/ProductosView.vue";
import PaisesView from "../views/maestros/PaisesView.vue";
import AtributosView from "../views/maestros/AtributosView.vue";
import CotizadorView from "../views/maestros/CotizadorView.vue";
import TiposContrato from "../views/maestros/TiposContrato.vue";
// Rutas modulos
import { GaranttoRoutes } from "@/modules/garantto/routes";
import { SoporteRoutes } from "@/modules/soporte/routes";
// Middlewares
import { authCheck, checkManageERPMiddleware, guestMiddleware } from "@/middlewares/authCheck.js";
import { callbackLogin } from "@/middlewares/callbackLogin.js";
import { erpRoutes } from "./erp.router.ts";

const routes = [
  {
    path: "/",
    component: DefaultLayout,
    children: [
      {
        path: "",
        name: "home",
        component: HomeView,
        meta: {
          title: "Home",
        },
      },
      {
        path: "/clientes",
        name: "clientes",
        component: ClientesView,
        meta: {
          title: "Lista de clientes",
        },
      },
      {
        path: "/clientes/crear",
        name: "cliente-crear",
        component: ClienteCrear,
        meta: {
          title: "Nuevo cliente",
        },
      },
      {
        path: "/clientes/:id",
        name: "cliente-editar",
        component: ClienteEditar,
        meta: {
          title: "Editar cliente",
        },
      },
      {
        path: "/contactos",
        name: "contactos",
        component: ContactosView,
        meta: {
          title: "Lista de contactos",
        },
      },
      {
        path: "/paises",
        name: "paises",
        component: PaisesView,
        meta: {
          title: "Países",
        },
      },
      {
        path: "/atributos",
        name: "atributos",
        component: AtributosView,
        meta: {
          title: "Atributos",
        },
      },
      {
        path: "/cotizador",
        name: "cotizador",
        component: CotizadorView,
        meta: {
          title: "Cotizador",
        },
      },
      {
        path: "/productos",
        name: "productos",
        component: ProductosView,
        meta: {
          title: "Productos",
        },
      },
      {
        path: "/tipos-contrato",
        name: "tipos-contrato",
        component: TiposContrato,
        meta: {
          title: "Tipos de contrato",
        },
      },
      ...GaranttoRoutes,
      ...SoporteRoutes,
    ],
    beforeEnter: [authCheck],
  },
  {
    path: "/erp",
    component: DefaultLayout,
    children: erpRoutes,
    beforeEnter: [authCheck, checkManageERPMiddleware],
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
    meta: {
      title: "Login",
    },
    props: true,
    beforeEnter: [authCheck],
  },
  {
    path: "/callback-login/:responseLogin",
    name: "callback-login",
    beforeEnter: [guestMiddleware, callbackLogin],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "../views/NotFoundView.vue"),
    meta: {
      title: "Página no encontrada",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Actualizar el title automaticamente
router.beforeEach((to, from, next) => {
  const { title } = to?.meta || {};
  document.title = `Intranet ${title ? `| ${title}` : ""}`;
  next();
});

export default router;
