<template>
  <section>
    <div class="header mb-3">
      <div class="flex flex-wrap gap-3">
        <div class="flex align-items-center mr-3">
          <RadioButton v-model="type" id="byScript" name="type" value="script" />
          <label for="byScript" class="ml-2">Por script</label>
        </div>
        <div class="flex align-items-center">
          <RadioButton v-model="type" id="byDatabase" name="type" value="database" />
          <label for="byDatabase" class="ml-2">Por base de datos</label>
        </div>
      </div>
    </div>
    <div class="content">
      <ScriptHistoryByScript v-if="type === 'script'" :scripts="listByScript" @delete="handleDeleteItem" />
      <ScriptHistoryByDatabase v-else :scripts="listByDatabase" />
    </div>
  </section>
</template>
<script setup>
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import ScriptHistoryByScript from '@/components/ERP/ScriptHistoryByScript.vue';
import ScriptHistoryByDatabase from '@/components/ERP/ScriptHistoryByDatabase.vue';
import { getErpDatabaseScriptHistory } from '../../services/erpDatabaseAdministrator.service'
import RadioButton from 'primevue/radiobutton';

const store = useStore();
const history = ref([]);
const type = ref('script');

onMounted(async () => {
  store.commit("setIsLoading", true)
  const { status, scripts } = await getErpDatabaseScriptHistory();
  store.commit("setIsLoading", false)
  if (status === 200)
    history.value = scripts;
});

const listByScript = computed(() => {
  return history.value.map((script) => {
    const totalErrors = script.results.filter((result) => result.EsError).length;
    return {
      ...script,
      total: script.results.length,
      totalErrors,
    };
  });
});

const listByDatabase = computed(() => {
  const data = history.value.reduce((acc, script) => {
    script.results.forEach((result) => {
      // Crear la entrada si no existe
      if (!acc[result.BaseDatos])
        acc[result.BaseDatos] = { BaseDatos: result.BaseDatos, total: 0, totalErrors: 0, results: [] };

      // Agregar los resultados
      acc[result.BaseDatos].results.push({
        Script: script.NombreArchico,
        FechaEjecucion: result.Fecha,
        Mensaje: result.Mensaje,
      });

      // Actualizar los totales
      acc[result.BaseDatos].total++;
      if (result.EsError) acc[result.BaseDatos].totalErrors++;
    });
    return acc;
  }, {})
  return Object.values(data);
});

/**
 *
 * @param {Array} index
 */
const handleDeleteItem = async ({ index }) => {
  const [scriptIndex, resultIndex] = index;

  if (index.length === 1) {
    history.value.splice(scriptIndex, 1);
  } else {
    history.value[scriptIndex].results.splice(resultIndex, 1);
  }
}

</script>
