import store from "../store";
import { checkToken } from "@/services/auth";

const verifySession = async () => {
  let isLogged = store.getters["auth/isLogged"];

  if (!isLogged) {
    store.commit("setIsLoading", true);
    const { data } = await checkToken() ?? {};
    const { user, asset_url } = data ?? {};
    store.commit("setIsLoading", false);

    if (user) {
      isLogged = true;
      store.commit("auth/login", user);
      store.commit("auth/setAssetUrl", asset_url);
    }
  }

  return isLogged;
}

export const authCheck = async (to, from, next) => {
  let isLogged = await verifySession();

  // Si esta logueado y trata de acceder a login, redirigir al home
  if (to.name == "login" && isLogged) next("/")
  // Si no esta logueado y trata de acceder a una ruta protegida, redirigir a login
  else if (!isLogged && to.name != "login") next({ name: "login" });
  else next();
};

export const guestMiddleware = async (to, from, next) => {
  let isLogged = await verifySession();

  if (isLogged) next("/");
  else next();
}

export const checkManageERPMiddleware = async (to, from, next) => {
  if (!store.getters["auth/canManageERP"]) return next({ name: "home" });
  next();
}
