<template>
  <div class="border-round-md shadow-lg bg-white">
    <table class="script-history-by-script-main-table">
      <thead class="bg-cyan-500 text-white">
        <tr>
          <th>DB</th>
          <th>Total</th>
          <th>Avance</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(item, index) in scripts" :key="item.Script">
          <tr>
            <td class="text-center">
              <span class="text-700">{{ item.BaseDatos }}</span>
            </td>
            <td class="text-center">
              <Tag :value="item.total"></Tag>
            </td>
            <td>
              <ProgressBar :value="calculePercentage(item)"></ProgressBar>
            </td>
            <td>
              <Button type="button" :icon="`pi ${index == selectedIndex ? 'pi-minus' : 'pi-plus'}`"
                class="p-button-rounded p-button-primary p-button-text" @click="toggleDetail(index)" />
            </td>
          </tr>
          <tr class="subtable" :class="{ 'd-none': index != selectedIndex }">
            <td colspan="4">
              <table class="table-striped">
                <thead class="text-center">
                  <tr>
                    <th class="text-left">Script</th>
                    <th class="text-left">Estado</th>
                    <th class="text-left">Fecha</th>
                    <th class="text-left">Mensaje</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(child) in item.results" :key="`script-childre-${child.Id}`">
                    <tr>
                      <td>{{ child.Script }}</td>
                      <td>
                        <Tag :value="child.EsError ? 'Error' : 'Correcto'"
                          :class="{ 'p-tag-danger': child.EsError, 'p-tag-success': !child.EsError }" />
                      </td>
                      <td>{{ child.FechaEjecucion }}</td>
                      <td>{{ child.Mensaje }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>
<script setup>
import { defineProps, ref } from 'vue';
import ProgressBar from 'primevue/progressbar';
import Button from 'primevue/button';
import Tag from 'primevue/tag';

defineProps({
  scripts: Array,
})
const selectedIndex = ref(null);

const calculePercentage = ({ total, totalErrors }) => {
  if (total === 0) return 0;
  if (totalErrors === 0) return 100;
  const calcule = (1 - (totalErrors / total)) * 100;
  return Math.round(calcule);
}

const toggleDetail = (index) => {
  if (selectedIndex.value === index) {
    selectedIndex.value = null;
  } else {
    selectedIndex.value = index;
  }
}
</script>

<style lang="scss" scoped>
.d-none {
  display: none;
}

.table-striped {
  tbody {
    tr:nth-child(odd) {
      background-color: #f2f2f2;
    }
  }
}

table {
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    padding: .3rem;
  }
}
</style>
