<template>
  <aside class="sidebar" :class="{ closed: !sidebarVisible }">
    <div class="user-details">
      <span class="user-name">{{ `${user.Nombres} ${user.Apellidos}` }}</span>
    </div>
    <left-bar-panel :menu="menu" />
  </aside>
</template>

<script>
import LeftBarPanel from "@/components/layout/LeftBarPanel.vue";
import { mapState, mapGetters } from "vuex";
import { enlacesMenu } from '@/data/enlacesMenu'

export default {
  components: {
    LeftBarPanel,
  },
  data() {
    return {
      esVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      dbPais: "gpais/getPais",
    }),
    ...mapState(["auth", "sidebarVisible"]),
    user() {
      return this.auth?.user ?? {};
    },
    menu() {
      return enlacesMenu(this.dbPais?.IdT.toLowerCase())
    }
  },
  watch: {
    dbPais(newValue) {
      this.menu = enlacesMenu(newValue?.IdT.toLowerCase())
    },
  }
};
</script>
