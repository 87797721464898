import DatabaseAdministratorView from "@/views/erp/DatabaseAdministratorView.vue";
import ScriptHistory from "@/views/erp/ScriptHistory.vue";

export const erpRoutes = [
  {
    path: "administrador-base-datos",
    name: "erp.administrador-base-datos",
    component: DatabaseAdministratorView,
    meta: {
      title: "Administrador de base de datos",
    },
  },
  {
    path: "historial-scripts",
    name: "erp.historial-scripts",
    component: ScriptHistory,
    meta: {
      title: "Historial de scripts",
    },
  },
]
