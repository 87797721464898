<template>
  <ul class="nav-links">
    <template v-for="(item, i) in menu" :key="i">
      <!-- Basic -->
      <li v-if="item?.items == undefined && (item?.visible ?? true) == true">
        <app-link :to="item.to" @click="onRouterClick" exact>
          <i :class="item.icon"></i>
          <span class="link-name">{{ item.label }}</span>
        </app-link>
        <ul class="sub-menu blank">
          <li>
            <app-link :to="item.to" class="link-name" exact>
              {{ item.label }}
            </app-link>
          </li>
        </ul>
      </li>
      <!-- Submenu -->
      <li class="treeview" v-else-if="(item?.visible ?? true) == true">
        <div @click="abrirSubmenu" class="icon-link">
          <a href="#">
            <i :class="item.icon"></i>
            <span class="link-name">{{ item.label }}</span>
          </a>
          <i class="pi pi-angle-down"></i>
        </div>
        <ul class="sub-menu">
          <li>
            <a class="link-name" href="#">{{ item.label }}</a>
          </li>
          <li v-for="(subitem, j) in item?.items || []" :key="j">
            <app-link :to="subitem.to" @click="onRouterClick" exact>
              {{ subitem.label }}
            </app-link>
          </li>
        </ul>
      </li>
    </template>
  </ul>
</template>

<script>
import { mapState } from "vuex";
import AppLink from "@/components/AppLink.vue";
export default {
  props: {
    menu: {
      type: Array,
      required: true,
    },
  },
  components: { AppLink },
  mounted() {
    this.setActiveTree();
  },
  methods: {
    setActiveTree() {
      const lastActiveTree = document.querySelector("li.treeview.router-link-exact-active");
      if (lastActiveTree) lastActiveTree?.classList.remove("router-link-exact-active");
      const link = document.querySelector("a.router-link-exact-active");
      if (link) link.closest(".treeview")?.classList.toggle("router-link-exact-active");
    },
    abrirSubmenu({ target }) {
      const treeview = target.closest(".treeview");
      const curr = document.querySelector("li.showMenu");
      if (curr) {
        if (curr != treeview) curr.classList.remove("showMenu");
      }
      treeview.classList.toggle("showMenu");
    },
    onRouterClick() {
      if (window.innerWidth <= 767) this.$store.commit('setSidebarVisible');
    },
  },
  watch: {
    sidebarVisible(value) {
      if (!value) return;
      const treeviews = document.querySelectorAll("li.treeview");
      if (treeviews) {
        treeviews.forEach((el) => {
          el.classList.contains("router-link-exact-active")
            ? el.classList.add("showMenu")
            : el.classList.remove("showMenu");
        });
      }
    },
    $route() {
      setTimeout(() => {
        this.setActiveTree();
      }, 100);
    },
  },
  computed: {
    ...mapState(["sidebarVisible"])
  },
};
</script>
