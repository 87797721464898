import axios from "./config";

export const checkToken = async () => {
  const { data, status } = await axios.get("/auth/me").catch(e => e?.response ?? {})
  return status == 200 ? data : null
}

export const logout = async () => {
  const { status } = await axios.post("/auth/logout").catch(e => e?.response ?? {})
  return status == 204
}
